import axios from "axios";

export const api =  axios.create({
    // baseURL: window.location.origin
    baseURL: "https://dapi.2t.sd",
    // withCredentials: true
});

export const getUsers = async () => {
    const response = await api.get('/dashboard/users');
    return response.data;
}

export const getTrans = async () => {
    const response = await api.get('/dashboard/transactions');
    return response.data;
}