import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { api } from "../../api/axios";
import { useQuery } from "react-query";
import { Link, useParams } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import moment from "moment";
import Loader from "../Loader";

// function createData(name, calories, fat, carbs, protein) {
//   return { name, calories, fat, carbs, protein };
// }

export default function BasicTable() {
  const { id } = useParams();
  // Fetching transactions data
  const getTranInfo = async () => {
    const response = await api.get(`/dashboard/transactions/${id}`);
    return response.data;
  };

  const {
    isLoading,
    refetch,
    isError,
    data: transData,
  } = useQuery(["getTranInfo"], getTranInfo);

  console.log(id);

  if (isLoading) {
    return <Loader />;
  }
  return (
    <>
      <div>
        <h4 className="text-xl mb-3">Transaction ID: {id}</h4>
      </div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell align="left">Pan</TableCell>
              <TableCell align="left">Amount</TableCell>
              <TableCell align="left">Phone number</TableCell>
              <TableCell align="left">status</TableCell>
              <TableCell align="left">client ID</TableCell>
              <TableCell align="left">view</TableCell>
            </TableRow>
          </TableHead>  
          <TableBody>
            {isError ? (
               <p className="py-5 px-2 text-lg font-light">
               *Cannot retrieve data at this moment.
             </p>
            ) : (
              transData?.data?.map((row) => (
                <TableRow
                  key={row.name}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.ID}
                  </TableCell>
                  <TableCell align="left">{row.ID}</TableCell>
                  <TableCell align="left">{row.ID}</TableCell>
                  <TableCell align="left">{row.ID}</TableCell>
                  <TableCell align="left">{row.ID}</TableCell>
                  <TableCell align="left">{row.ID}</TableCell>
                  <TableCell align="left">
                    <Link className="edit-btn" to={`/transactions/${row.ID}`}>
                      <IconButton>
                        <VisibilityIcon />
                      </IconButton>
                    </Link>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
