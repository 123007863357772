import { Box, Toolbar, Typography } from "@mui/material";
import { Apexchart } from "../components";

const Home = () => {
    
    return ( 
        <section>
        <Apexchart/>
      </section>
     );
}
 
export default Home;