import { useState, useContext } from "react";
import {
  AppBar,
  Avatar,
  Box,
  Button,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { authContext } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";

const Header = ({ toggleFunc }) => {
  const menu = [
    {
      title: "Profile",
      icon: <AccountCircleIcon />,
    },
    {
      title: "Logout",
      icon: <ExitToAppIcon />,
    },
  ];

  const { isAuthenticated } = useContext(authContext);
  const Navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const HandleLogout = (e) => {
    Navigate("/login");
  };
  return (
    <AppBar
      position="fixed"
      style={{ zIndex: "1300", backgroundColor: "#F9550E" }}
      xs={{
        color: "black",
      }}
      elevation={0}
    >
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={toggleFunc}
          sx={{ mr: 2, display: { sm: "none" } }}
        >
          <MenuIcon />
        </IconButton>
        <div className="logo">
          <img
            src={require("../assets/images/logo.png")}
            width="45"
            alt="logo"
          />
        </div>
        <Typography
          sx={{ flexGrow: 1 }}
          variant="h6"
          component="div"
          fontWeight="fontWeightMedium"
          mx={1}
        >
          Tutipay
        </Typography>
        <Box sx={{ flexGrow: 0 }}>
          <Tooltip
            title="Open Options"
            style={{ cursor: "pointer" }}
            onClick={handleClick}
          >
            <ListItem disablePadding>
              <Avatar
                alt="Remy Sharp"
                src={require("../assets/images/avatar.jpg")}
              />
              <ListItemText
                style={{ margin: "0 10px", color: "white" }}
                primary="Hamadok"
                primaryTypographyProps={{
                  textTransform: "capitalize",
                }}
              />
            </ListItem>
          </Tooltip>
          <Menu
            sx={{ mt: "45px" }}
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            disableScrollLock={true}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <Paper elevation={0} sx={{ width: 180, maxWidth: "100%" }}>
              <MenuItem
                onClick={() => {
                  handleClose();
                  Navigate("/profile");
                }}
              >
                <ListItemIcon>
                  <AccountCircleIcon />
                </ListItemIcon>
                Profile
              </MenuItem>
              <MenuItem onClick={() => HandleLogout()}>
                <ListItemIcon>
                  <ExitToAppIcon />
                </ListItemIcon>
                Logout
              </MenuItem>
            </Paper>
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
