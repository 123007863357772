import Chart from "react-apexcharts";

const Apexchart = () => {
  const options = {
    chart: {
      id: "basic-bar",
    },
    stroke: {
      width: 4,
      curve: "smooth",
    },
    xaxis: {
      categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999],
    },
  };

  const series = [
    {
      name: "series-1",
      data: [30, 40, 45, 50, 49, 60, 70, 91],
    },
  ];

  return (
    <div className="app">
      <div className="mixed-chart">
        <Chart
          options={options}
          series={series}
          type="line"
          width="100%"
          height="500"
        />
      </div>
    </div>
  );
};

export default Apexchart;