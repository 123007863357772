import { SelectFilter, UsersTable } from "../components";

const Users = () => {
    return ( 
        <section className="users-page">
            <div className="table-container">
            <UsersTable />
            </div>
        </section>
     );
}
 
export default Users;