const Loader = () => {
    return ( 
        <div className="loader-container flex items-center justify-center">
    <div className="spinner-container">
      <div className="loading-spinner">
      </div>
    </div>
    </div>
     );
}
 
export default Loader;