import { useEffect } from "react";
import { api } from "../api/axios";
import { SingleTransactionTable, TransactionsTable } from "../components";

const Transactions = () => {
  return (
    <section className="transactions h-full">
      <div className="table-container">
        <TransactionsTable />
      </div>
      <hr className="my-5" />
      <h4 className="text-xl mb-3">Search for a transaction:</h4>
      <div className="table-container">
        <SingleTransactionTable />
      </div>
    </section>
  );
};

export default Transactions;
