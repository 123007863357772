import { useState, useContext, useEffect } from "react";
import { Stack } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Route, Routes, useLocation } from "react-router-dom";
import { Header, ProtectedRoute, Sidebar } from "./components";
import { Agents, ClientsProfile, Home, Login, Profile, Reports, Transactions, Users } from "./pages";
import { authContext } from "./context/AuthContext";
import TransactionInfo from "./pages/TransactionInfo";

function App() {
  const {pathname} = useLocation();
  const { isAuthenticated } = useContext(authContext);
  const [mobileOpen, setMobileOpen] = useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const theme = createTheme({});
  console.log(isAuthenticated)
  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        {pathname !== "/login" && <Header toggleFunc={handleDrawerToggle} />}
        <Stack direction="row" spacing={1}>
          {pathname !== "/login" && (
            <Sidebar open={mobileOpen} close={handleDrawerToggle} />
          )}
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route element={<ProtectedRoute isAuthenticated={isAuthenticated} />}>
            <Route path="/" element={<Home />} />
            <Route path="/users" element={<Users />} />
            <Route path="/users/:id" element={<ClientsProfile />} />
            <Route path="/transactions" element={<Transactions />} />
            <Route path="/transactions/:id" element={<TransactionInfo />} />
            <Route path="/agents" element={<Agents />} />
            <Route path="/reports" element={<Reports />} />
            <Route path="/profile" element={<Profile />} />
            </Route>
          </Routes>
        </Stack>
      </div>
    </ThemeProvider>
  );
}

export default App;