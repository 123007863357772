import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import "./index.css";
import App from "./App";
import AuthContextProvider from "./context/AuthContext";
import { QueryClient, QueryClientProvider } from "react-query";

const root = ReactDOM.createRoot(document.getElementById("root"));
const queryClient = new QueryClient();
root.render(
  <React.StrictMode>
        <QueryClientProvider client={queryClient}>
    <AuthContextProvider>
      <Router>
          <App />
      </Router>
    </AuthContextProvider>
        </QueryClientProvider>
  </React.StrictMode>
);
