import { useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Container, Stack } from "@mui/system";
import { Apexchart, Barchart, ClientTable } from "../components";
import { useQuery } from "react-query";
import { getUsers } from "../api/axios";

const Profile = () => {
  // const {data} = useQuery('getUsers', getUsers);
  // console.log(data);
  const [disabled, setDisabled] = useState(true);
  return (
    <section className="profile">
      <Box
        sx={{
          textAlign: "center",
          margin: "auto",
          mb: 5,
        }}
      >
        <Avatar
          alt="Remy Sharp"
          src={require("../assets/images/avatar.jpg")}
          sx={{ width: 120, height: 120, margin: "auto", mb: 1 }}
        />
        <Typography variant="h5" component="h1">
          Hamadok Zoolna
        </Typography>
      </Box>

      <Container>
        <Box mb={3} textAlign="center">
          <Button
            variant="contained"
            color="primary"
            onClick={() => setDisabled(false)}
          >
            Edit info
          </Button>
        </Box>
        {/* <Stack direction="row" sx={{ mb: 4 }}> */}
          {/* <Box textAlign="center"> */}
          <div className="grid md:grid-cols-2 gap-x-4">
            <TextField
              id="outlined-basic"
              className="din"
              style={{ marginBottom: "20px", width: "100%" }}
              variant="outlined"
              value="Mohamed Ahmed"
              // onChange={(e) => setUsername(e.target.value)}
              disabled={disabled}
            />
            <TextField
              id="outlined-basic"
              className="din"
              style={{ marginBottom: "20px", width: "100%" }}
              variant="outlined"
              value="username"
              // onChange={(e) => setUsername(e.target.value)}
              disabled={disabled}
            />

            <FormControl>
              <InputLabel id="select-label">Role</InputLabel>
              <Select
                style={{
                  marginBottom: "20px",
                  width: "100%",
                  textAlign: "left",
                }}
                labelId="select-label"
                id="role-select"
                value={"admin"}
                label="Status"
                disabled={disabled}
                // onChange={handleChange}
              >
                <MenuItem value="admin">Admin</MenuItem>
                <MenuItem value="superagent">Super agent</MenuItem>
                <MenuItem value="agent">Agent</MenuItem>
              </Select>
            </FormControl>

            <TextField
              id="outlined-basic"
              className="din"
              style={{ marginBottom: "20px", width: "100%" }}
              variant="outlined"
              value="Omdurman"
              // onChange={(e) => setUsername(e.target.value)}
              disabled={disabled}
            />
          {/* </Box> */}
          {/* <Box textAlign="center"> */}
            <TextField
              id="outlined-basic"
              className="din"
              style={{ marginBottom: "20px", width: "100%" }}
              variant="outlined"
              value="email@mail.com"
              // onChange={(e) => setUsername(e.target.value)}
              disabled={disabled}
            />
            <TextField
              id="outlined-basic"
              className="din"
              style={{ marginBottom: "20px", width: "100%" }}
              variant="outlined"
              value="+249123456789"
              // onChange={(e) => setUsername(e.target.value)}
              disabled={disabled}
            />
            <FormControl>
              <InputLabel id="demo-simple-select-label">Status</InputLabel>
              <Select
                style={{
                  marginBottom: "20px",
                  width: "100%",
                  textAlign: "left",
                }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={"active"}
                label="Status"
                disabled={disabled}
                // onChange={handleChange}
              >
                <MenuItem value="active">Active</MenuItem>
                <MenuItem value="suspend">Suspend</MenuItem>
              </Select>
            </FormControl>

            {/* <TextField
              id="outlined-basic"
              className="din"
              style={{ marginBottom: "20px", width: "100%" }}
              variant="outlined"
              value="Active"
              // onChange={(e) => setUsername(e.target.value)}
              disabled={disabled}
            /> */}
            <Button
              variant="contained"
              style={{
                marginBottom: "20px",
                padding: "15px 0",
                width: "100%",
                background: "#f9550e",
              }}
            >
              Save
            </Button>
          {/* </Box> */}
        {/* </Stack> */}
        </div>
      </Container>
    </section>
  );
};

export default Profile;
