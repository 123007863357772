import { createContext, useEffect, useState } from "react";
import { api } from "../api/axios";

export const authContext = createContext();

const AuthContextProvider = (props) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const getIsAuthenticated = async () => {
    try {
      const response = await api.get("/isauthenticated");
      response && setIsAuthenticated(response?.data?.authenticated);
    } catch (err) {
      err && setIsAuthenticated(err?.response?.data?.authenticated);
    }
  };

  // console.log(isAuthenticated);
  useEffect(() => {
    getIsAuthenticated();
  }, []);

  useEffect(() => {
    const data = localStorage.getItem("isAuth");
    setIsAuthenticated(data);
  }, []);

  useEffect(() => {
    localStorage.setItem("isAuth", isAuthenticated);
    // getIsAuthenticated();
  }, [isAuthenticated]);

  return (
    <authContext.Provider value={{ isAuthenticated }}>
      {props.children}
    </authContext.Provider>
  );
};

export default AuthContextProvider;
