import { TransactionInfoTable } from "../components";

const TransactionInfo = () => {
    return (  
        <section className="transaction-info">
            <TransactionInfoTable />
        </section>
    );
}
 
export default TransactionInfo;